import { 
        UserCircleIcon, 
        KeyIcon,
        EyeIcon
} from '@heroicons/react/24/solid'
import reportWebVitals from './reportWebVitals';
import { useState } from 'react'
const App = () => {
  const [ toggle, setToggle ] = useState(true)
  const url = new URL(window.location.href).searchParams
  let message = ""
  const base = url.get("m")

  if(base !== "" && base){
    message = atob(base)
  }
 
  return (
    <div className="h-screen bg-[#15417C] overflow-hidden grid grid-cols-1  content-center">
      <div className="flex justify-center">
        <div className="sm:w-[70%] 2xl:w-[50%] bg-white lg:grid grid-cols-2 rounded-xl shadow-lg">
          <div className="p-10 sm:p-5 sm:px-10 pt-[17px]">
            <div className="font-extrabold -ml-0 text-center text-[#15417C] text-[60px] sm:text-[80px] ">
              BIOS
            </div>
            <div className="text-slate-500 font-bold text-3xl text-center">LOGIN</div>
            <div className="text-slate-400 text-center text-sm mb-2 sm:mb-0 sm:text-md mt-1">Mohon Login Terlebih Dahulu</div>
            <form action="/api/login" method="POST">
            
            <div className="">
                {
                  message !== "" ?
                    <div className='bg-red-500 px-2 py-1 text-slate-50 my-2'>{ message }</div>
                  :   
                    null
                }
                <div className="text-slate-500 mb-1">Username</div>
                  <div className="relative">
                      <UserCircleIcon className="w-5 absolute left-2 top-[10px] text-slate-400"/>
                      <input type="text" name="username" className="w-full border-none bg-slate-200 rounded-md pl-8" />
                  </div>

                <div className="text-slate-500 mt-3 mb-1">Password</div>
                <div className="relative">
                    <KeyIcon className="w-5 absolute left-2 top-[10px] text-slate-400"/>
                    <input name="password" type={ toggle ? "password" : "text" } className="w-full border-none bg-slate-200 rounded-md pl-8" />
                    <EyeIcon onClick={ () => setToggle(!toggle) } className="w-5 absolute right-2 top-[10px] text-slate-400"/>
                </div>
                <button type="submit" className="bg-blue-500 text-slate-50 p-2 rounded-md w-full mt-4">MASUK</button>  
              </div>
          </form>
          </div>

          <div className="rounded-r-xl relative min-h-[500px] hidden lg:block" style={{ background: `url("${require('./bg-login.png')}")`, backgroundSize: "cover" }}>
              <div className="p-10 pt-[17px] justify-between gap-2">
                <div className="">
                    
                    <div className="font-bold text-[80px] text-center text-white"> 
                      PEM
                    </div>
                    <div className="font-bold text-[40px] -mt-6 text-center text-white opacity-70"> 
                      AKAMIGAS
                    </div>
                </div>
                <div className="font-bold text-[30px] text-center pt-10 text-white/60">
                  "Energi Untuk Negeri"
                </div>
                <div className="font-bold text-[15px] text-center pt-0 text-white/60">
                  Politeknik Energi dan Mineral Akamigas
                </div>

                <div className="flex absolute bottom-10 w-full justify-center left-0 ">
                    <div>
                      <div className="p-1 bg-white/40 h-[60px] px-4 rounded-l-md pt-2">
                        <img src={ require("./pem-akamigas.png") } className="w-[40px] -mt-[5px]" alt="" />                
                      </div>
                    </div>
                    <div className="relative">
                      <hr className="border-[1px] absolute top-7 -ml-6 rotate-90 w-[50px]"/>
                    </div>
                    <div>
                      <div className="p-1 bg-white/40 h-[60px] px-4 pt-3 border-slate-300 rounded-r-md">
                        <img src={ require("./blu-ready.png") } className="w-[40px]" alt="" />                

                      </div>
                    </div>
                </div>
                
              </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default App;
